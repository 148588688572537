import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Bootstrap_Custom.css";
import "./Style.css";
import "./admin.css";
import "./responsive.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import PrivateRoute from "./component/Route/PrivateRoute";
import React from "react";
import Home from "./component/Home/Home";
import Category from "./component/Category/Category.js";
// import Search from "./component/Category/CategorySearch.js";
import SingleProduct from "./component/SingleProduct/SingleProduct.js";
import CustomSize from "./component/SingleProduct/CustomSize.js";
import UserAccount from "./component/Account/UserAccount.js";
import ProductDetails from "./component/Product/ProductDetails";
import Products from "./component/Product/Products";
import Search from "./component/Product/Search";
import OrderDetails from "./component/Order/OrderDetails.js";
import OrderConfirmation from "./component/Order/OrderConfirmation.js";
import { useSelector } from "react-redux";
import Dashboard from "./component/Admin/Dashboard.js";
import AddProduct from "./component/Admin/addProduct.js";
import UpdateProduct from "./component/Admin/updateProduct.js";
import UpdateInventory from "./component/Admin/updateInventory.js";
import AddCategory from "./component/Admin/addCategory.js";
import AllCategory from "./component/Admin/allCategory.js";
import AllProduct from "./component/Admin/allProduct.js";
import EditHome from "./component/Admin/editHome.js";
import Orders from "./component/Admin/orders.js";
import OrdersDetail from "./component/Admin/orders_details.js";
import Users from "./component/Admin/users.js";
import Testimonials from "./component/Admin/testimonials.js";
import DiscountCopouns from "./component/Admin/discountCopouns.js";
import Customers from "./component/Admin/customers.js";
import Login from "./component/Admin/login.js";
import LoginSignUp from "./component/User/LoginSignUp.js";
import ForgotPassword from "./component/User/ForgotPassword.js";
import ForgotPass from "./component/Admin/forgotPass.js";
import Cart from "./component/Cart/Cart.js";
import BuyNow from "./component/Cart/BuyNow.js";
import Wishlist from "./component/Cart/Wishlist.js";
import Checkout from "./component/Cart/Checkout.js";
import Contact from "./component/layout/Contact/Contact";
import About from "./component/layout/About/About.js";
import NotFound from "./component/layout/Not Found/NotFound";
import AddUser from "./component/Admin/addUser.js";
import SizeCustomization from "./component/Admin/sizeCustomization.js";
import Header from "./component/layout/Header/Header.js";

import Return from "./component/OtherPages/Return.js";
import Shipping from "./component/OtherPages/Shipping.js";
import PrivacyPolicy from "./component/OtherPages/PrivacyPolicy.js";
import TermsOfService from "./component/OtherPages/TermsOfService.js";
import FAQ from "./component/OtherPages/FAQ.js";
import Payment from "./component/Cart/Payment.js";
import Success from "./component/Cart/Success.js";
import Cancel from "./component/Cart/Cancel.js";
import { updateOrder } from "./actions/orderAction.js";
import Loader from "./component/layout/Loader/Loader.js";
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.GA_TRACKING_ID);

function App(props) {
  const { isAuthenticated, user, loading } = useSelector((state) => state.user);
  useEffect(() => {
    console.log("props", props);
  }, [props]);
  // const location = useLocation();
  const showHeader = !["/exclude", "/other-exclude"].includes(props.location);

  window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <Router>
      {loading ? <Loader /> : <></>}
      <Route
        render={({ location }) =>
          ![
            "/loginsignup",
            "/admin",
            "/dashboard",
            "/forgotpassword",
            "/forgotpass",
          ].includes(location.pathname.toLowerCase()) ? (
            !location.pathname.toLowerCase().startsWith("/admin/") ? (
              <Header></Header>
            ) : (
              ""
            )
          ) : (
            ""
          )
        }
      />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/category/:cat/:id" component={Category} />
        <Route exact path="/category/:cat" component={Category} />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/AddProduct"
          component={AddProduct}
        />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/UpdateProduct"
          component={UpdateProduct}
        />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/updateInventory"
          component={UpdateInventory}
        />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/AddCategory"
          component={AddCategory}
        />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/AllCategory"
          component={AllCategory}
        />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          exact
          path="/Admin/AllProduct"
          component={AllProduct}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Adduser"
          component={AddUser}
        />
        <ProtectedRoute
          isAdmin={true}
          isEmployee={true}
          exact
          path="/Admin/sizeCustomization"
          component={SizeCustomization}
        />
        <ProtectedRoute
          isAdmin={true}
          isEmployee={true}
          exact
          path="/Admin/EditHome"
          component={EditHome}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/Orders"
          component={Orders}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/OrdersDetail"
          component={OrdersDetail}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/Users"
          component={Users}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/testimonials"
          component={Testimonials}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/discountCopouns"
          component={DiscountCopouns}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/Admin/customers"
          component={Customers}
        />
        <Route exact path="/product/:id" component={ProductDetails} />
        <Route exact path="/products" component={Products} />
        <Route
          exact
          path="/singleproduct/:id/p/:pid"
          component={SingleProduct}
        />
        <Route
          exact
          path="/singleproduct/:id/p/:pid/:cid"
          component={SingleProduct}
        />
        <Route exact path="/Admin" component={Login} />
        <Route exact path="/LoginSignUp" component={LoginSignUp} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/Cart" component={Cart} />
        <Route exact path="/BuyNow" component={BuyNow} />
        <PrivateRoute
          isUser={true}
          exact
          path="/Wishlist"
          component={Wishlist}
        />
        <PrivateRoute
          isUser={true}
          exact
          path="/Checkout"
          component={Checkout}
        />
        <PrivateRoute isUser={true} exact path="/Return" component={Return} />
        <Route
          isUser={true}
          exact
          path="/CustomSize/:id"
          component={CustomSize}
        />
        <PrivateRoute
          isUser={true}
          exact
          path="/UserAccount"
          component={UserAccount}
        />
        <PrivateRoute isUser={true} exact path="/Success" component={Success} />
        <PrivateRoute isUser={true} exact path="/Cancel" component={Cancel} />
        <Route exact path="/Shipping" component={Shipping} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/TermsOfService" component={TermsOfService} />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/ForgotPass" component={ForgotPass} />
        <Route path="/products/:keyword" component={Products} />
        <Route exact path="/search/:query" component={Search} />
        <Route exact path="/contact" component={Contact} />
        <PrivateRoute exact path="/OrderDetails" component={OrderDetails} />
        <Route exact path="/OrderConfirmation" component={OrderConfirmation} />
        <Route exact path="/about" component={About} />
        <ProtectedRoute
          isAdmin={true}
          isEditor={true}
          isEmployee={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />
        <Route exact path="/*" component={Home} />
      </Switch>

      {/* <Footer /> */}
    </Router>
  );
}

export default App;
