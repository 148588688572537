import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function Orders() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [recentOrders, setRecentOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [payType, setPayType] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    handleFilter();
  }, [recentOrders, startDate, endDate, status, payType, searchText]);

  const handleFilter = () => {
    if (!startDate && !endDate && !status && !payType && !searchText) {
      setFilteredOrders(recentOrders);
      return;
    }
    let filtered = recentOrders;
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filtered = recentOrders.filter((order) => {
        const orderDate = new Date(order.created);
        return orderDate >= start && orderDate <= end;
      });
    }
    if (status) {
      filtered = filtered.filter((order) => order.status === status);
    }
    if (payType) {
      filtered = filtered.filter((order) => order.pay_type === payType);
    }
    // Filter by search term
    if (searchText) {
      const lowerCaseSearchTerm = searchText.toLowerCase();
      filtered = filtered.filter((order) => {
        // Assuming you want to search by order id, customer name, or any other field
        return (
          order.order_id.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
          order.first_name.toLowerCase().includes(lowerCaseSearchTerm) ||
          order.last_name.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });
    }
    setFilteredOrders(filtered);
  };

  async function getRecentOrders() {
    let res = await fetch("/api/admin/orders/all");
    if (res.ok) {
      let data = await res.json();
      setRecentOrders(data);
    }
  }


  async function updateOrderStatus(id, status) {
    let res = await fetch("/api/admin/orders/" + id, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status
      }),
    });
    if (res.ok) {
      getRecentOrders();
    } else {
      alert("Something went wrong")
    }
  }



  useEffect(() => {
    getRecentOrders();
  }, []);

  // const exportToExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(filteredOrders);
  //   const workbook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ['data'],
  //   };
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   });
  //   const data = new Blob([excelBuffer], {
  //     type:
  //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  //   });
  //   saveAs(data,  'orders.xlsx');
  // };

  const exportToExcel = () => {
    const table = document.getElementById("all_orders_table");
    const workbook = XLSX.utils.table_to_book(table);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "orders.xlsx");
  };

  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
  };


  return (
    <>
      <Header></Header>
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Orders</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">All Orders</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="categoryFilter">
                    <div className="row align-items-end">
                      <div className="col-md-4">
                        <label className="mt-3">Search</label>
                        <input
                          type="text"
                          placeholder="Search Order..."
                          className="form-control"
                          value={searchText}
                          onChange={onFilterTextChange}
                        />
                      </div>
                      <div className="col-md-2">
                        <label>
                          Start Date:
                          <input
                            className="form-control"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label>
                          End Date:
                          <input
                            placeholder="MM/DD/YYYY"
                            className="form-control"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label>
                          Status:
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="paid">Paid</option>
                            <option value="pending">Pending</option>
                            <option value="failed">Failed</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-md-2">
                        <label>
                          Currency:
                          <select className="form-control" value={payType}
                            onChange={(e) => setPayType(e.target.value)}>
                            <option value="">All</option>
                            <option value="PayPal">PayPal ($)Dollar</option>
                            <option value="Stripe">Stripe (₹)Rupees</option>
                            <option value="Razorpay">Razorpay (₹)Rupees</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-md-12 text-right">
                        <button className="blackBtn" onClick={exportToExcel}>
                          <i className="fa-solid fa-file-export"></i> Export
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="tableOuter adminTables">
                      <table
                        className="phoenix-table fs-9 table"
                        id="all_orders_table"
                      >
                        <thead>
                          <tr>
                            {/* <th className="" style={{ width: "30px;" }}>
                              <div className="form-check fs-8 mb-0">
                                #
                              </div>
                            </th> */}
                            <th
                              className="pe-3 ps-0 sort"
                              style={{ width: "5%" }}
                            >
                              Order
                            </th>
                            <th
                              className="text-end sort"
                              style={{ width: "6%;" }}
                            >
                              Total
                            </th>
                            <th
                              className="ps-8 sort"
                              style={{ width: "20%", minWidth: "250px;" }}
                            >
                              Customer
                            </th>
                            <th
                              th
                              className="pe-3 sort"
                              style={{ width: "14%" }}
                            >
                              Payment status
                            </th>
                            <th
                              className="pe-3 sort"
                              style={{ width: "14%", textAlign: "center" }}
                            >
                              Order Location
                            </th>
                            <th
                              className="pe-3 sort"
                              style={{ width: "14%", minWidth: "200px;" }}
                            >
                              Fulfilment status
                            </th>
                            <th className="text-end sort">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredOrders.length > 0 &&
                            filteredOrders.map((recentOrder) => (
                              <tr key={recentOrder.order_id}>
                                <td scope="row">
                                  <Link
                                    className="fw-semi-bold"
                                    to={{
                                      pathname: "/admin/ordersdetail",
                                      state: {
                                        id: recentOrder.order_id,
                                      },
                                    }}
                                  >
                                    #{recentOrder.order_id}
                                  </Link>
                                </td>
                                <td>
                                  {recentOrder.pay_type === "Stripe" || recentOrder.pay_type === "Razorpay"
                                    ? "Rs. "
                                    : "$ "}{" "}
                                  {recentOrder.amount_charged}
                                </td>
                                <td>
                                  <Link
                                    className="fw-semi-bold"
                                    to={{
                                      pathname: "/admin/ordersdetail",
                                      state: {
                                        id: recentOrder.order_id,
                                      },
                                    }}
                                  >
                                    <p className="mb-0 ms-3 text-1100 fw-bold">
                                      {`${recentOrder.first_name} ${recentOrder.last_name}`}
                                    </p>
                                  </Link>
                                </td>
                                <td>
                                  <span
                                    className={`badge bg-${recentOrder.status === "paid"
                                      ? "success"
                                      : recentOrder.status === "failed"
                                        ? "danger"
                                        : "warning"
                                      }`}
                                  >
                                    {recentOrder.status.toUpperCase()}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span className="badge bg-success">
                                    {recentOrder.country}
                                  </span>
                                </td>
                                <td>
                                  {recentOrder.status === 'paid' ? recentOrder.order_status === 'completed' ?
                                    <span
                                      className="badge bg-success"
                                    >
                                      Completed
                                    </span>
                                    :
                                    <select
                                      className="form-select"
                                      onChange={(e) => { updateOrderStatus(recentOrder.order_id, e.target.value) }}
                                    >
                                      <option selected value="pending">Pending</option>
                                      <option value="completed">Completed</option>
                                    </select> : "-"}
                                </td>
                                <td className="text-700 fs-9 ps-4 text-end white-space-nowrap">
                                  {recentOrder.created}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Orders;
