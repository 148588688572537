import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { CsvExportParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Header from "./header";
import Footer from "./footer";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CustomModal from "../layout/CustomModal";
import { Link } from "react-router-dom";

function AllProduct() {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  const [deleteID, setDeleteID] = useState("");
  const [deleteType, setDeleteType] = useState("");
  // const [searchText, setSearchText] = useState("");

  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [deleteMultiple, setdeleteMultiple] = useState([]);

  const handlePopupClose = () => {
    setShowPopupSuccess(false); // Close the popup
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleConfirm = () => {
    setShowPopup(false);
    if (deleteType === "single") {
      deleteProduct();
    } else {
      DeleteAll();
    }
  };

  function getAllProducts() {
    fetch("/api/admin/products")
      .then((response) => response.json())
      .then((data) => {
        if (data?.products) {
          setRowData(data.products);
        }
      });
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  function editProduct(e, id) {
    e.preventDefault();
    history.push("/admin/updateproduct", id);
  }

  function editInventory(e, id) {
    e.preventDefault();
    history.push("/admin/updateInventory", id);
  }

  function confirm_deleteProduct(id) {
    setDeleteType("single");
    setDeleteID(id);
    setShowPopup(true);
  }

  function deleteProduct() {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: deleteID,
      }),
    };
    fetch("/api/admin/products", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.products) {
          setRowData(flattenData(data.products));
          setPopupContent("Product Deleted ID: " + deleteID);
          setShowPopupSuccess(true);
        } else {
          setPopupContent("Error ");
          setShowPopupSuccess(true);
        }
      });
  }

  const columnDefs = [
    {
      headerName: "",
      cellRenderer: checkboxRenderer,
      maxWidth: 50,
      sortable: false,
      floatingFilter: false,
      filter: false,
      headerTooltip: "action",
    },
    { headerName: "ID", field: "id", filter: false, maxWidth: 90 },
    // { headerName: "Product Image", field: "image", cellRenderer: ImageRenderer, filter: false, },
    {
      headerName: "Product Name",
      cellRenderer: LinkRenderer,
      filter: false,
      maxWidth: 300,
      field: "name",
    },
    // { headerName: "Stock", field: "quantity" },
    // { headerName: "Price", field: "price" },
    { headerName: "Style No", field: "style_no", filter: false, maxWidth: 130 },
    { headerName: "Date", field: "created", maxWidth: 150, filter: false },
    {
      headerName: "Action",
      cellRenderer: ActionRenderer,
      cellRendererParams: { deleteProduct },
      sortable: false,
      floatingFilter: false,
      filter: false,
    },
  ];

  function checkboxRenderer(field) {
    return (
      <div>
        <input
          type="checkbox"
          checked={deleteMultiple.includes(field.data.id)}
          onClick={(e) => changedeleteMultiple(field.data.id)}
        />
      </div>
    );
  }
  function changedeleteMultiple(id) {
    let multiple = [...deleteMultiple];
    if (multiple.includes(id)) {
      // If the value is already selected, remove it
      const updatedValues = multiple.filter(
        (selectedValue) => selectedValue !== id
      );
      setdeleteMultiple(updatedValues);
    } else {
      // If the value is not selected, add it
      const updatedValues = [...deleteMultiple, id];
      setdeleteMultiple(updatedValues);
    }
  }

  function DeleteAll() {
    console.log("deleteMultiple", deleteMultiple);
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: deleteMultiple,
      }),
    };
    fetch("/api/admin/products/all", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          getAllProducts();
          alert("Selected Product deleted");
        } else {
          alert("Error");
        }
      });
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }
  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    applyCategoryFilter(e.target.value);
  };

  const applyCategoryFilter = (value) => {
    gridApi.setQuickFilter(value);
  };
  function ImageRenderer(props) {
    return (
      <img
        src={process.env.REACT_APP_BASE_URL + props.value}
        className="CategoryImage"
      />
    );
  }

  function LinkRenderer(props) {
    return (
      <div>
        <Link
          to={`/singleproduct/${props.data.name}/p/${props.data.variant_id}`}
        >
          {props.data.name}
        </Link>
      </div>
    );
  }

  const handleCheckboxChange = async (id, checked) => {
    // API call
    try {
      var res = await fetch("/api/admin/products/state", {
        body: JSON.stringify({ id, checked }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        getAllProducts();
        setPopupContent("Product Updated: " + id);
        setShowPopupSuccess(true);
      } else {
        getAllProducts();
        setPopupContent("Error while updating Product.");
        setShowPopupSuccess(true);
      }
    } catch (error) {
      console.error("Error calling the API:", error);
    }
  };

  function ActionRenderer(props) {
    return (
      <div className="actions">
        <div className="publish_unpublish">
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              defaultChecked={props.data.active === "Y" ? true : false}
              onChange={(e) =>
                handleCheckboxChange(props.data.id, e.target.checked)
              }
              className="onoffswitch-checkbox"
              id={`myonoffswitch_${props.data.id}`}
              tabindex="0"
            />
            <label
              className="onoffswitch-label"
              for={`myonoffswitch_${props.data.id}`}
            >
              <span className="onoffswitch-inner"></span>
              <span className="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
        {/* <button className="viewbtn" ><i className="fa-regular fa-eye"></i>View</button> */}
        <button
          className="editBtn"
          onClick={(e) => props.data && editProduct(e, props.data.id)}
        >
          <i className="fa-regular fa-pen-to-square"></i>Edit
        </button>
        <button
          className="editInvBtn"
          onClick={(e) => props.data && editInventory(e, props.data.id)}
        >
          <i className="fa-regular fa-pen-to-square"></i>Edit Inventory
        </button>
        <button
          className="dltBtn"
          onClick={(e) => props.data && confirm_deleteProduct(props.data.id)}
        >
          <i className="fa-regular fa-trash-can"></i>Delete
        </button>
      </div>
    );
  }

  const defaultColDef = {
    sortable: true,
    filter: false,
    flex: 1,
    floatingFilter: false,
    sort: "asc",
  };

  function flattenData(data) {
    const flattenedData = [];
    data.forEach((category) => {
      // Add the category row
      flattenedData.push({ ...category, isCategory: true });

      // Add subcategory rows
      if (category.subcategories && category.subcategories.length > 0) {
        category.subcategories.forEach((subcategory) => {
          flattenedData.push({
            ...subcategory,
            isCategory: false,
            parentCategoryId: category.id,
          });
        });
      }
    });
    return flattenedData;
  }
  const exportToCsv = () => {
    const params = {
      suppressQuotes: false, // Set to true if you want to suppress quoting values
      fileName: "exported-products.csv",
      columnSeparator: ",", // Set the column separator (optional, default is ',')
    };

    gridApi.exportDataAsCsv(params);
  };
  function rowClassRules(params) {
    return params.data.isCategory ? { "category-row": true } : null;
  }

  function confirm_deleteAll() {
    setDeleteType("multiple");
    setShowPopup(true);
  }

  return (
    <>
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopupSuccess}
        onClose={handlePopupClose}
      />
      <Modal show={showPopup} onHide={handleClose} className="alertsPop">
        <div className="row">
          <div className="col-md-12">
            <div className="deleteIcon">
              <i className="fa-solid fa-trash-can"></i>
            </div>
          </div>
          <div className="col-md-12">
            <p>Are you sure you want to delete this Product?</p>
          </div>
          <div className="col-md-12 mt-5">
            <div className="actionBtns">
              <button
                className="blackbtn"
                onClick={(e) => {
                  handleConfirm("Y");
                }}
              >
                Yes
              </button>
              <button className="outlinedBtn" onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Products</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">All Products</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="g-5 row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="categoryFilter">
                                <div className="row">
                                  <div className="col-md-4">
                                      <input
                                      className="form-control"
                                        type="text"
                                        placeholder="Search Product..."
                                        value={searchText}
                                        onChange={onFilterTextChange}
                                      />
                                  </div>
                                  <div className="col-md-8 text-right">
                                    <button
                                      className="blackBtn m-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        exportToCsv();
                                      }}
                                    >
                                      <i className="fa-solid fa-file-export"></i>{" "}
                                      Export
                                    </button>
                                    <button
                                      className="outlinedBtn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // DeleteAll();
                                        confirm_deleteAll();
                                      }}
                                    >
                                      <i className="fa-regular fa-trash-can"></i>{" "}
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="tableOuter adminTables">
                              <div
                                className="ag-theme-alpine"
                                style={{ height: "500px", width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  rowData={rowData}
                                  onGridReady={onGridReady}
                                  pagination={true}
                                  paginationPageSize={10}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default AllProduct;
