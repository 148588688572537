import React, { Fragment, useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Loader from "../layout/Loader/Loader";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import ConfirmPopup from "./confirmPopup";
import CustomModal from "../layout/CustomModal";
// import Resizer from "react-image-file-resizer";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddCategory({ location, history }) {
  const [parentchk, setParentchk] = useState(
    location?.state?.parent_category_id ? false : false
  );
  const [cAvailable, setCAvailable] = useState(
    location?.state?.customization ? true : false
  );
  const [name, setname] = useState(location?.state?.name ?? "");
  const [desc, setdesc] = useState(location?.state?.description ?? "");
  const [tag, setTag] = useState(location?.state?.tag ?? "");
  const [file, setFile] = useState(
    location?.state?.image
      ? process.env.REACT_APP_BASE_URL + location?.state?.image
      : "../images/dummy-image-square.jpg"
  );
  const [file2, setFile2] = useState(
    location?.state?.image
      ? process.env.REACT_APP_BASE_URL + location?.state?.banner
      : "../images/dummy-image-square.jpg"
  );

  const [parentCat, setparentCat] = useState(
    location?.state?.parent_category_id ?? ""
  );
  const [subCat, setSubCat] = useState("");
  const [Categories, setCategories] = useState([]);
  const [SubCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonName, setButtonName] = useState(
    location?.state?.id ? "Update " : "Publish "
  );
  const [rowData, setRowData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [customizations, setCustomizations] = useState(location?.state?.customization_values ? location?.state?.customization_values.split(" ,, ") : []);
  const [allCustomizations, setAllCustomizations] = useState([]);

  const [is_lehenga_waist, setIs_lehenga_waist] = useState(
    location?.state?.is_lehenga_waist ?? false
  );
  const [is_lehenga_length, setIs_lehenga_length] = useState(
    location?.state?.is_lehenga_length ?? false
  );
  const [is_height, setIs_height] = useState(
    location?.state?.is_height ?? false
  );
  const [is_sleeve_length, setIs_sleeve_length] = useState(
    location?.state?.is_sleeve_length ?? false
  );
  const [is_padding, setis_padding] = useState(
    location?.state?.is_padding ?? false
  );
  const [is_saree_length, setIs_saree_length] = useState(
    location?.state?.is_saree_length ?? false
  );
  const [is_saree_waist, setIs_saree_waist] = useState(
    location?.state?.is_saree_waist ?? false
  );
  const [is_petti_coat, setIs_petti_coat] = useState(
    location?.state?.is_petti_coat ?? false
  );
  const [customizationOptions, setCustomizationOptions] = useState([]);


  const handleChange_customization = (event) => {
    const {
      target: { value },
    } = event;
    setCustomizations(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  function getCustomizations() {
    setLoading(true);
    fetch(`/api/admin/customization/${tag}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setAllCustomizations(data);
        }
        setLoading(false);
      });
  }
  useEffect(() => {
    getCustomizations();
  }, [tag]);

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  function getCategory() {
    fetch(`/api/admin/category/parent`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          setCategories(data);
        }
      });
  }

  function getSubCategory() {
    if (parentCat !== "")
      fetch(`/api/admin/category/cat/${parentCat}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length) {
            setSubCategories(data);
          }
        });
  }

  useEffect(() => {
    setLoading(false);
    getCustomizations();
    if (parentCat) {
      getSubCategory();
    } else {
      setSubCat("");
      setSubCategories([]);
    }
  }, [parentCat]);

  function handleChange(event) {
    const originalFile = event.target.files[0];
    setFile(originalFile);
    // if (originalFile)
    // Resizer.imageFileResizer(
    //   originalFile,
    //   400, // max width
    //   550, // max height
    //   "png", // output format same as original file format
    //   70, // quality
    //   0, // rotation
    //   (uri) => {
    //     console.log("uri", uri)
    //     setFile(uri);
    //   },
    //   'file' // output type
    // );
  }

  function handleChange2(event) {
    const originalFile = event.target.files[0];
    setFile2(originalFile);
    // if (originalFile)
    //   Resizer.imageFileResizer(
    //     originalFile,
    //     1920, // max width
    //     352, // max height
    //     "png", // output format same as original file format
    //     70, // quality
    //     0, // rotation
    //     (uri) => {
    //       setFile2(uri);
    //     },
    //     'file' // output type
    //   );
  }

  useEffect(() => {
    getCategory();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (parentchk) {
      setparentCat("");
    }
  }, [parentchk]);

  async function addNewCategory() {
    setLoading(true);
    try {
      var p_cat = "";
      if (subCat !== "") {
        p_cat = subCat;
      } else {
        p_cat = parentCat;
      }
      var url = "/api/admin/category";
      if (location?.state?.id) {
        url = "/api/admin/category/" + location?.state?.id;
      }
      const requestOptions = {
        method: location?.state?.id ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          description: Buffer.from(desc).toString("base64"),
          tag: tag,
          parent_id: p_cat,
          customization: cAvailable,
          customization_values: Buffer.from(customizations.join(" ,, ")).toString("base64"),
          is_lehenga_waist,
          is_lehenga_length,
          is_height,
          is_sleeve_length,
          is_padding,
          is_saree_length,
          is_saree_waist,
          is_petti_coat,
        }),
      };
      var res = await fetch(url, requestOptions);
      if (res.ok) {
        var data = await res.json();

        if (data?.id) {
          var product_id = data.id;
          if (typeof file == "object") {
            var formData = new FormData();
            formData.append("image", file);
            formData.append("id", product_id);
            res = await fetch("/api/admin/category/image", {
              body: formData,
              method: "post",
            });
          }
          if (typeof file2 == "object") {
            var formData = new FormData();
            formData.append("image_banner", file2);
            formData.append("id", product_id);
            res = await fetch("/api/admin/category/image", {
              body: formData,
              method: "post",
            });
          }
          if (res.ok) {
            setLoading(false);
            getCategory();
            getAllCategories();
            setPopupContent(
              `Category ${location?.state?.id ? "updated" : "created"
              } Successfully.`
            );
            setShowPopup(true);
            location?.state?.id && history.push("/admin/allCategory");
            setname("");
            setdesc("");
            setTag("");
            setSubCat("");
            setparentCat("");
            setFile("../images/dummy-image-square.jpg");
            setFile2("../images/dummy-image-square.jpg");
          }
        } else {
          setLoading(false);
          getCategory();
          getAllCategories();
          setPopupContent(
            `Category ${location?.state?.id ? "updated" : "created"
            } Successfully.`
          );
          setShowPopup(true);
          location?.state?.id && history.push("/admin/allCategory");
          setname("");
          setdesc("");
          setTag("");
          setSubCat("");
          setparentCat("");
          setFile("../images/dummy-image-square.jpg");
          setFile2("../images/dummy-image-square.jpg");
        }
      } else if (res.status === 403) {
        setLoading(false);
        setPopupContent("category already exists");
        setShowPopup(true);
      } else {
        setLoading(false);
        setPopupContent("Error updating category: " + res.statusText);
        setShowPopup(true);
      }
    } catch (e) {
      setLoading(false);
      setPopupContent("Error in uploading product: " + e);
      setShowPopup(true);
    }
  }

  const TreeNode_child = ({ node }) => {
    const [isExpanded, setExpanded] = useState(false);

    const handleToggle = () => {
      setExpanded(!isExpanded);
    };

    return (
      <div>
        <div onClick={handleToggle} className="sub_category">
          <i className="fa-regular fa-folder"></i>
          {node.subcategories.length ? (isExpanded ? "" : "") : ""} {node.name}
        </div>
        {isExpanded && node.subcategories && (
          <div className="subsubCat">
            {node.subcategories.map((childNode, index) => (
              <TreeNode_child key={index} node={childNode} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const TreeNode = ({ node }) => {
    const [isExpanded, setExpanded] = useState(false);

    const handleToggle = () => {
      setExpanded(!isExpanded);
    };

    return (
      <div className="main_category">
        <div onClick={handleToggle} className="catParent">
          <i className="fa-regular fa-folder-open"></i>
          {node.subcategories.length ? (isExpanded ? "" : "") : ""} {node.name}
        </div>
        {isExpanded && node.subcategories && (
          <div className="subbCat">
            {node.subcategories.map((childNode, index) => (
              <TreeNode_child key={index} node={childNode} />
            ))}
          </div>
        )}
      </div>
    );
  };
  const Tree = ({ data }) => {
    return (
      <div>
        {data.length &&
          data.map((node, index) => <TreeNode key={index} node={node} />)}
      </div>
    );
  };

  async function getAllCategories() {
    try {
      const response = await fetch("/api/admin/category/all");
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const data = await response.json();
      if (data.length) {
        setRowData(data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  }

  const [newOption, setNewOption] = useState("");

  const handleAddOption = (e) => {
    e.preventDefault();
    if (newOption.trim() !== "") {
      setCustomizationOptions([...customizationOptions, { name: newOption }]);
      setNewOption("");
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = customizationOptions.filter((_, i) => i !== index);
    setCustomizationOptions(updatedOptions);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <ConfirmPopup></ConfirmPopup>

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="pagetitle">
                <h1>Add Category</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add Category</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex align-items-center gap-2">
              <button
                type="submit"
                className="whiteBtn"
                onClick={addNewCategory}
              >
                {" "}
                {buttonName} Category{" "}
              </button>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="g-5 row">
                    <div className="col-xl-8 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">List of Categories</h5>
                          {rowData.length > 0 ? (
                            <Tree data={rowData} />
                          ) : (
                            "No Categories found"
                          )}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Category Title</h5>
                          <input
                            value={name}
                            onInput={(e) =>
                              setname(
                                e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                              )
                            }
                            placeholder="Enter Category Title"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Category Short Description
                          </h5>
                          <ReactQuill
                            value={desc}
                            onChange={(value) => setdesc(value)}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"], // toggled buttons
                                ["blockquote", "code-block"],
                                [{ header: 1 }, { header: 2 }], // custom button values
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                                [{ direction: "rtl" }], // text direction
                                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"], // remove formatting button
                              ],
                            }}
                          />
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Category Filters</h5>
                          <div className="cSingleFilter">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Padding"
                                    checked={is_lehenga_waist}
                                    onChange={(e) =>
                                      setIs_lehenga_waist(!is_lehenga_waist)
                                    }
                                  />
                                  <label htmlFor="Padding">Lehenga waist</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Waist"
                                    checked={is_lehenga_length}
                                    onChange={(e) =>
                                      setIs_lehenga_length(!is_lehenga_length)
                                    }
                                  />
                                  <label htmlFor="Waist">
                                    Lehenga Length (waist to floor including
                                    Heels)
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Length"
                                    checked={is_height}
                                    onChange={(e) => setIs_height(!is_height)}
                                  />
                                  <label htmlFor="Length">
                                    Select Your Height (Including Heels)
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Height"
                                    checked={is_sleeve_length}
                                    onChange={(e) =>
                                      setIs_sleeve_length(!is_sleeve_length)
                                    }
                                  />
                                  <label htmlFor="Height">Sleeve Length</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Height"
                                    checked={is_padding}
                                    onChange={(e) => setis_padding(!is_padding)}
                                  />
                                  <label htmlFor="Height">
                                    Padding Required?
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Height"
                                    checked={is_saree_length}
                                    onChange={(e) =>
                                      setIs_saree_length(!is_saree_length)
                                    }
                                  />
                                  <label htmlFor="Height">Saree Length</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Height"
                                    checked={is_saree_waist}
                                    onChange={(e) =>
                                      setIs_saree_waist(!is_saree_waist)
                                    }
                                  />
                                  <label htmlFor="Height">Saree Waist</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="filGroup mb-2">
                                  <input
                                    type="checkbox"
                                    id="Height"
                                    checked={is_petti_coat}
                                    onChange={(e) =>
                                      setIs_petti_coat(!is_petti_coat)
                                    }
                                  />
                                  <label htmlFor="Height">Petti Coat</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Parent Category</h5>
                          <div className="gx-3 gy-4 row">
                            <div className="col-xl-12 col-sm-6 col-12">
                              <select
                                aria-label="category"
                                className="form-select"
                                disabled={parentchk}
                                value={parentCat}
                                onChange={(e) => setparentCat(e.target.value)}
                              >
                                <option value="">Select</option>
                                {Categories.map((category, index) => {
                                  return (
                                    <option key={index} value={category.id}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {SubCategories.length > 0 && (
                            <>
                              <h5 className="card-title">Sub Category</h5>
                              <div className="gx-3 gy-4 row">
                                <div className="col-xl-12 col-sm-6 col-12">
                                  <select
                                    aria-label="category"
                                    className="form-select"
                                    value={subCat}
                                    onChange={(e) => setSubCat(e.target.value)}
                                  >
                                    <option value="">Select</option>
                                    {SubCategories.map((category, index) => {
                                      return (
                                        <option key={index} value={category.id}>
                                          {category.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Category Tag</h5>
                          <select
                            aria-label="category"
                            className="form-select"
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="Men">Men</option>
                            <option value="Women">Women</option>
                            <option value="Kids">Kids</option>
                          </select>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Customization Available
                          </h5>

                          <div className="gx-3 gy-4 row">
                            <div className="col-xl-12 col-sm-6 col-12 custAvail">
                              <input
                                name="avail"
                                type="checkbox"
                                id="avail"
                                checked={cAvailable}
                                onClick={(e) => setCAvailable(!cAvailable)}
                              />
                              <label htmlFor="avail">Yes/No</label>
                            </div>
                            <div>
                              <ul>
                                {customizations.map((category) => (
                                  <li>{category}</li>
                                ))}
                              </ul>
                            </div>
                            {cAvailable ? <div>
                              <FormControl sx={{ m: 1, width: '100%' }}>
                                <label htmlFor="">Customization Fields</label>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={customizations}
                                  onChange={handleChange_customization}
                                  input={<OutlinedInput />}
                                  // renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                >
                                  {allCustomizations.map((category) => (
                                    <MenuItem key={category.id} value={category.name}>
                                      <Checkbox checked={customizations.indexOf(category.name) > -1} />
                                      <ListItemText primary={category.name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div> : ""}
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Category Image:{" "}
                            <span>(Size: 400 x 550 pixels)</span>
                          </h5>
                          <div
                            role="presentation"
                            tabindex="0"
                            className="mb-3 dropzone"
                          >
                            <input
                              accept="image/*,.png,.gif,.jpeg,.jpg"
                              multiple=""
                              type="file"
                              tabindex="-1"
                              style={{ display: "none" }}
                              onChange={handleChange}
                            />
                            <div className="text-600 fw-bold fs-9">
                              {" "}
                              Drag your photo here{" "}
                              <span className="text-800">or </span>
                              <button
                                type="button"
                                className="p-0 btn btn-link"
                              >
                                {" "}
                                Browse from device
                              </button>
                              <br />
                              <img
                                src={
                                  typeof file == "string"
                                    ? file
                                    : URL.createObjectURL(file)
                                }
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Category Banner Image:{" "}
                            <span>(Size: 1920 x 352 pixels)</span>
                          </h5>
                          <div
                            role="presentation"
                            tabindex="0"
                            className="mb-3 dropzone"
                          >
                            <input
                              accept="image/*,.png,.gif,.jpeg,.jpg"
                              multiple=""
                              type="file"
                              tabindex="-1"
                              style={{ display: "none" }}
                              onChange={handleChange2}
                            />
                            <div className="text-600 fw-bold fs-9">
                              {" "}
                              Drag your photo here{" "}
                              <span className="text-800">or </span>
                              <button
                                type="button"
                                className="p-0 btn btn-link"
                              >
                                {" "}
                                Browse from device
                              </button>
                              <br />
                              <img
                                src={
                                  typeof file2 == "string"
                                    ? file2
                                    : URL.createObjectURL(file2)
                                }
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Tags Images</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="singBan">
                                <img src="https://vastrakala.com/static/media/women.7ec60f71.jpg" />
                                <h4>Men</h4>
                                <input type="file" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="singBan">
                                <img src="https://vastrakala.com/static/media/women.7ec60f71.jpg" />
                              </div>
                              <h4>Women</h4>
                              <input type="file" />
                            </div>
                            <div className="col-md-6">
                              <div className="singBan">
                                <img src="https://vastrakala.com/static/media/women.7ec60f71.jpg" />
                              </div>
                              <h4>Kids</h4>
                              <input type="file" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Size Customization</h5>
                          <div className="size_custom_list">
                            <table>
                              {customizationOptions.length ? customizationOptions.map((option, index) =>
                                <tr key={index}>
                                  <td>{option.name}</td>
                                  <td className="text-right">
                                    <button className="delBtn" onClick={() => handleRemoveOption(index)}>
                                      <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                  </td>
                                </tr>)
                                : ""}
                            </table>
                          </div>
                          <div className="SizeAddition mt-3 text-right">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter"
                              value={newOption}
                              onChange={(e) => setNewOption(e.target.value)}
                            />
                            <button className="blackBtn mt-3" onClick={(e) => handleAddOption(e)}>Submit</button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default AddCategory;
