import React, { useState, useEffect } from "react";
import Footer from "../layout/Footer/Footer";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ShadeCard from "../../images/ShadeCard.pdf";
import CustomModal from "../layout/CustomModal";
import mannequin from "../../images/mannequin4.png";
import mannequinMen from "../../images/mannequinMen.png";
import Switch from '@mui/material/Switch';

function CustomSize({ location }) {
  const [focusedInputIndex, setFocusedInputIndex] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [allImages, setAllImages] = useState({});
  const history = useHistory();
  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange_check = (event) => {
    setChecked(event.target.checked);
  };

  const [inputValues, setInputValues] = useState({ "Special Instructions": "" });

  const handleFocus = (index) => {
    setFocusedInputIndex(index);
  };

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleDoneClick = async () => {
    localStorage.setItem(
      "customSizeData",
      JSON.stringify({ id: location.state.id, inputValues })
    );
    setPopupContent("Custom size data saved successfully!");
    setShowPopup(true);

    history.push(
      "/singleproduct/" + location.state.name + "/p/" + location.state.id
    );
  };

  const handleBackClick = () => {
    history.push(
      "/singleproduct/" + location.state.name + "/p/" + location.state.id
    );
  };

  const getAllImages = () => {
    fetch(`/api/products/customizationImages/${location.state.tag}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setAllImages(data);
        }
      });
  }

  useEffect(() => {
    console.log(location.state.customization_values)
    if (location.state?.customization_values) {
      const customizationValues = location.state.customization_values.split(" ,, ")
      let temp = {}
      // let i = 0
      for (let custom of customizationValues) {
        // if (i === 0) {
        //   setFocusedInputIndex(custom)
        //   i++
        // }
        temp[custom] = ""
      }
      temp["Special Instructions"] = ""
      setInputValues(temp)
    }
    getAllImages()
    let customSizeData = JSON.parse(localStorage.getItem("customSizeData"));
    if (customSizeData && customSizeData.id == location.state?.product_id)
      setInputValues(customSizeData.inputValues);
  }, []);

  return (
    <>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      {/* <Header></Header> */}
      <div className="customSizePage">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="modalDummyImage">
                {focusedInputIndex === "" ?
                  location.state.tag === "Women" ?
                    <img src={mannequin} alt="image"></img> :
                    <img src={mannequinMen} alt="image"></img> :
                  <img src={`${process.env.REACT_APP_BASE_URL}${allImages[focusedInputIndex]}`} alt="image" />}
              </div>
            </div>
            <div className="col-6 col-md-4" style={{ background:'#fff' }}>
              <div className="CustomSizeForm">
                <div className="row">
                  <div className="col-md-12">
                    <h2>CUSTOMISE: {location.state?.name}</h2>
                    <h4>
                      UNIT OF MEASUREMENT: <span>IN INCHES</span>
                    </h4>
                  </div>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-md-12">
                      {/* -----------Gharara Suit-------------- */}
                      <table >
                        <tbody>
                          {Object.keys(inputValues).map((key, index) => {
                            if (key !== "Special Instructions") {
                              return (
                                <tr>
                                  <td>
                                    <label>{key}</label>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={inputValues[key]}
                                      onChange={(e) =>
                                        handleChange(e, key)
                                      }
                                      onFocus={() => handleFocus(key)}
                                    />
                                  </td>
                                </tr>

                              )
                            }
                          })

                          }
                          <tr>
                            <td>
                              Do you want to change the color:

                            </td>
                            <td> <Switch checked={checked}
                              onChange={handleChange_check}
                              inputProps={{ 'aria-label': 'controlled' }} /></td>
                          </tr>
                          {checked ? <tr>
                            <td colSpan={2} className="shadeCard">
                              <Link to={ShadeCard} target="_blank">
                                Shade Card
                              </Link>
                            </td>
                          </tr> : <></>}

                          <tr>
                            <td colSpan={2} className="spclInst">
                              <textarea
                                name=""
                                id=""
                                rows="3"
                                placeholder="Special Instructions (If Any)"
                                value={inputValues["Special Instructions"]}
                                onChange={(e) =>
                                  handleChange(e, "Special Instructions")
                                }
                              ></textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <button className="doneBtn" onClick={handleDoneClick}>
                        Done
                      </button>
                    </div>
                    <div className="col-6">
                      <button className="backBtn" onClick={handleBackClick}>
                        Back
                      </button>
                    </div>
                    <div className="col-md-12">
                      <button className="backBtn">
                        LATER.MOVE TO WISHLIST
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-md-2 hideMob" style={{ background:'#fff' }}>
              {/* -----Image of product---- */}
              <div className="proCImage">
                <img src={process.env.REACT_APP_BASE_URL + location.state.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default CustomSize;
