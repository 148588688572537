import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import "../Cart/Checkout.css";
import { update_Address } from "../../actions/cartAction";
import CustomModal from '../layout/CustomModal'

const AddressModal = ({ type, showPopup, onClose, index, id }) => {

    const dispatch = useDispatch();
    const { shippingInfo, cartItems, wishlist } = useSelector(
        (state) => state.cart
    );
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [tmc, settmc] = useState(false);
    const [firstName, setFirstName] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["first_name"] : "" : ""
    );
    const [lastName, setLastName] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["last_name"] : "" : ""
    );
    const [country, setCountry] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["country"] : "" : ""
    );
    const [city, setCity] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["city"] : "" : ""
    );
    const [state, setState] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["state"] : "" : ""
    );
    const [address1, setAddress1] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["address1"] : "" : ""
    );
    const [address2, setAddress2] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["address2"] : "" : ""
    );
    const [zip, setZip] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["zip"] : "" : ""
    );
    const [mobileNo, setmobileNo] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["mobile"] : "" : ""
    );
    const [email, setemail] = useState(
        index !== '' ? shippingInfo.length > 0 ? shippingInfo[index]["email"] : "" : ""
    );

    const handleClose = () => {
        onClose('N'); // Call the onClose callback when the popup is closed
    };

    const handleConfirm = (value) => {
        onClose(value); // Call the onClose callback when the popup is closed
    };


    const [showPopup2, setShowPopup2] = useState(false);
    const [popupContent, setPopupContent] = useState('');

    const handlePopupClose = () => {
        setShowPopup2(false); // Close the popup
    };


    async function validateAddress() {
        if (!firstName || !lastName || !country || !state || !address1 || !zip || !mobileNo || !email) {
            return false;
        }

        await dispatch(
            update_Address(id, {
                address1,
                address2,
                mobile: mobileNo,
                email,
                state,
                zip,
                country,
                first_name: firstName,
                last_name: lastName,
                city,
            })
        );

        setPopupContent(`Address ${index !== '' ? "Updated successfully" : "Added Successfully"}`);
        setShowPopup2(true);

        return true;
    }

    async function addAddress(e) {
        e.preventDefault()
        let value = await validateAddress()
        if (value) {
            onClose('N')
        } else {
            alert("Please fill all required fileds");
        }
    }

    useEffect(() => {
        if (index !== '' && shippingInfo.length > 0) {
            setFirstName(shippingInfo[index]["first_name"] || "");
            setLastName(shippingInfo[index]["last_name"] || "");
            setCountry(shippingInfo[index]["country"] || "");
            setCity(shippingInfo[index]["city"] || "");
            setState(shippingInfo[index]["state"] || "");
            setAddress1(shippingInfo[index]["address1"] || "");
            setAddress2(shippingInfo[index]["address2"] || "");
            setZip(shippingInfo[index]["zip"] || "");
            setmobileNo(shippingInfo[index]["mobile"] || "");
            setemail(shippingInfo[index]["email"] || "");
        } else {
            setFirstName("");
            setLastName("");
            setCountry("");
            setCity("");
            setState("");
            setAddress1("");
            setAddress2("");
            setZip("");
            setmobileNo("");
            setemail("");
        }
    }, [index]);


    const [countries, setCountries] = useState("");


    function getCountries() {
        fetch("/api/countries")
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    setCountries(data);
                }
            });
    }

    useEffect(() => {
        getCountries();
    }, []);
    return (
        <>
            <CustomModal
                type="success"
                content={popupContent}
                showPopup={showPopup2}
                onClose={handlePopupClose}
            />

            <Modal show={showPopup} onHide={handleClose} className="nohide alertsPop addAddressPopup" animation={false}>
                <button className="closeModal" onClick={handleClose}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
                <div className="checkoutForm">
                    <form action="#">
                        <div className="row align-item-end">
                            <div className="col-md-6">
                                {/* <label htmlFor="">First Name</label> */}
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onInput={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                {/* <label htmlFor="">Last Name</label> */}
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onInput={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="col-md-12">
                                {/* <label htmlFor="">Email Address</label> */}
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onInput={(e) => setemail(e.target.value)}
                                />
                            </div>
                            <div className="col-md-12 position-relative">
                                <label htmlFor="" className="smallLabel">Country/Region</label>
                                <select
                                    value={country}
                                    onInput={(e) => setCountry(e.target.value)}
                                >
                                    <option value="" disabled selected hidden >Select Country</option>
                                    {countries && countries.map((country, index) => {
                                        return <option key={index} value={country.country_code}>{country.country_name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 position-relative">
                                <label htmlFor="" className="smallLabel">State/Town</label>
                                <input
                                    type="text"
                                    placeholder="State"
                                    value={state}
                                    onInput={(e) => setState(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 position-relative">
                                <label htmlFor="" className="smallLabel">City</label>
                                <input
                                    type="text"
                                    placeholder="City"
                                    value={city}
                                    onInput={(e) => setCity(e.target.value)}
                                />
                            </div>

                            <div className="col-md-12">
                                {/* <label htmlFor="">Address</label> */}
                                <input
                                    type="text"
                                    placeholder="Address"
                                    value={address1}
                                    onInput={(e) => setAddress1(e.target.value)}
                                />
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="">Address Lane 2 (Optional)</label>
                                <input
                                    type="text"
                                    value={address2}
                                    onInput={(e) => setAddress2(e.target.value)}
                                />
                            </div> */}
                            <div className="col-md-6">
                                {/* <label htmlFor="">Zip/Postal Code</label> */}
                                <input
                                    type="text"
                                    placeholder="Zip/Postal Code"
                                    value={zip}
                                    onInput={(e) => setZip(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                {/* <label htmlFor="">Phone Number</label> */}
                                <input
                                    type="number"
                                    placeholder="Phone Number"
                                    value={mobileNo}
                                    onInput={(e) => setmobileNo(e.target.value)}
                                />
                            </div>
                            <div className="col-6 text-left">
                                <button className="outlinedBtn" onClick={onClose}>Go Back</button>
                            </div>
                            <div className="col-6 text-right">
                                <button className="blackbtn" onClick={(e) => addAddress(e)}>{index !== '' ? "Update Address" : "Save"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default AddressModal;
